import { connect, useDispatch } from "react-redux";
import { getComposicionByFilter } from "../../../actions/cuentacorriente";
import { useEffect, useState } from "react";
import { COMPOSICION_COLUMN } from "./interface/columnData";
import TableCustom from "../../../common/components/table/table";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import cuentaCorrienteService  from "../../../services/cuentacorriente";
import IFilterComposicion from "./interface/IFilterComposicion";
import Filter from "./filter";
import IClienteIdFilter from "../../../common/interface/IClienteIdFilter";
import Descarga from "../descarga";
import TipoComprobanteMapper from "../../../helper/TipoComprobanteMapper";

const Composicion = (props: any) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilterComposicion>({
        clienteId: localStorage.clienteId.trim(),
        documento: '',
        page: 0,
        rowPerPage: 5
    })
    const [clienteFilter, setClienteFilter] = useState<IClienteIdFilter>({
        clienteId: localStorage.clienteId.trim()
    })
    const getComposicion = (page: number, rowsPerPage: number): void => {
        setError(false);
        filter.rowPerPage= rowsPerPage;
        filter.page= page;
        dispatch(getComposicionByFilter(filter));
    };
    const getFilterComposicion = ():void => {
        setError(false);
        setPage(0);
        setRowsPerPage(5);
        filter.rowPerPage= 5;
        filter.page= 0;
        dispatch(getComposicionByFilter(filter));
    }
    useEffect(() => {
        dispatch(getComposicionByFilter(filter));
    }, []);

    // useEffect(() => {
    //     console.log(props.composicion)
    //     // if (props.estadopedidos.data) {
    //     //     setAcopios(props.acopios?.data.$values);
    //     // }
    // }, [props]);

    const handleDownloadExcel = async () => {
        setLoading(true);
        setError(false);
        const response = await cuentaCorrienteService.downloadFileComposicion(clienteFilter);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'composicion_cuenta_corriente.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
    };

    const handleDownloadPdf = async (item: any) => {
        setLoadingPdf(true);
        setError(false);
        try {
        const response = await cuentaCorrienteService.downloadComprobanteComposicion(localStorage.documentoNro.trim(), item.documento, item.parcela);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const tipo= TipoComprobanteMapper.mapperNombreComprobante(item.tipo);
        link.setAttribute('download', `bdb${localStorage.documentoNro.trim()}bol${item.documento}_${item.parcela}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoadingPdf(false);
        } catch (error) {
            setError(true);
            setLoadingPdf(false);
        }
    };

    return (
        <div style={{ margin: "2vw" }}>
            <div className="row">
                <div className="col-10">
                    <Filter 
                        getFilterComposicion={getFilterComposicion}
                        filter={filter}
                        onSelectedFilter={setFilter}
                    />             
                </div>   
                <div className="col-2">
                    <Descarga loading={loading} handleDownload={handleDownloadExcel}></Descarga> 
                </div>   
            </div>
            { error && 
                <Stack sx={{ width: '100%', margin: '2vh' }} spacing={2}>
                    <Alert severity="error">No se encuentra el documento.</Alert>
                </Stack>
            }
            <TableCustom page={page} 
                    onSelectedPage={setPage} 
                    rowsPerPage={rowsPerPage} 
                    onSelectedRowPerPage={setRowsPerPage} 
                    items={props.composicion?.composicionCtaCteResponses}
                    length={props.composicion?.length} 
                    getItems={getComposicion}
                    columns={COMPOSICION_COLUMN}
                    handleDownload={handleDownloadPdf}
                    loading={loadingPdf}
                />
        </div>
    );
};
export default connect(
    (state: any) => ({
        composicion: state.cuentacorriente.composicion,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getComposicionByFilter: (filter: IFilterComposicion) => dispatch(getComposicionByFilter(filter))
    })
)(Composicion);
